import {CommonModule} from '@angular/common';
import {Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {App} from 'src/app/app';
import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {ButtonType, UnoButtonComponent} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoContentComponent} from 'src/app/components/uno/uno-content/uno-content.component';
import {UnoIconComponent} from 'src/app/components/uno/uno-icon/uno-icon.component';
import {Service} from 'src/app/http/service';
import {ServiceList} from 'src/app/http/service-list';
import {Locale} from 'src/app/locale/locale';
import {Modal} from 'src/app/modal';
import {APAsset} from 'src/app/models/asset-portfolio/asset';
import {Resource} from 'src/app/models/resource';
import {UserPermissions} from 'src/app/models/users/user-permissions';
import {UUID} from 'src/app/models/uuid';
import {InspectionProjectService} from 'src/app/modules/inspections/services/inspection-project.service';
import {Session} from 'src/app/session';
import {SortDirection} from 'src/app/utils/sort-direction';
import {InputOptionsMultipleBatchRequest, InputOptionsMultipleLazyPageRequest} from 'src/app/components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {AssetCalendarListComponent} from '../asset-calendar-list/asset-calendar-list.component';
import {PermissionsPipe} from '../../../../pipes/permissions.pipe';

@Component({
	selector: 'asset-info',
	templateUrl: 'asset-info.component.html',
	styleUrls: ['asset-info.component.css'],
	standalone: true,
	imports: [
		CommonModule,
		UnoButtonComponent,
		UnoContentComponent,
		TranslateModule,
		UnoIconComponent,
		PermissionsPipe
	]
})
export class AssetInfoComponent {

	public app: any = App;

	public type: any = ButtonType;

	public session: any = Session;

	public permissions: any = UserPermissions;

	/**
	 * The Asset UUID associated with the QR Code.
	 */
	@Input()
	public asset: APAsset = null;


	/**
	 * Create an inspection for the asset.
	 */
	public async createInspection(): Promise<void> {
		const project: {inspectionProjectUuid: UUID} = {inspectionProjectUuid: null};

		const ProjectLayout: UnoFormField[] = [{
			required: true,
			attribute: 'inspectionProjectUuid',
			label: 'inspectionProject',
			multiple: false,
			type: UnoFormFieldTypes.OPTIONS_MULTIPLE_LAZY,
			identifierAttribute: 'uuid',
			fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
				const data = {
					from: request.from,
					count: request.count,
					search: request.search,
					sortField: '[inspection_project].[name]',
					sortDirection: SortDirection.ASC
				};

				try {
					const req = await InspectionProjectService.list(data);
					request.onFinish(req.projects, req.hasMore, req.id);
				} catch {
					request.onError();
				}
			},
			fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
				if (request.options.length > 0) {
					const data = {uuid: request.options[0]};

					const req = await Service.fetch(ServiceList.inspection.project.get, null, null, data, Session.session);

					request.onFinish(req.response.project.uuid);
				} else {
					request.onFinish([]);
				}
			},
			getOptionText: function(option: any): string {
				return (option.name ? option.name : '') + (option.description ? ' - ' + option.description : '');
			}
		}];

		await Modal.form(Locale.get('project'), project, ProjectLayout);

		App.navigator.navigate('/menu/inspection/edit', {createMode: true, project: project.inspectionProjectUuid, asset: this.asset.uuid});
	}

	/**
	 * Create a new atex inspection and navigate to it.
	 */
	public async createAtexInspection(): Promise<void> {
		const atexInspectionUUID: UUID = (await Service.fetch(ServiceList.atex.inspection.createForAsset, null, null, {assetUuid: this.asset.uuid}, Session.session)).response.uuid;

		App.navigator.navigate('/menu/atex/inspections/edit', {uuid: atexInspectionUUID});
	}

	/**
	 * Gets a image by its string/Resource.
	 *
	 * @returns - Path to the image.
	 */
	public getImage(): string {
		let image = this.asset.pictures.length > 0 ? this.asset.pictures[0] : '';
		if (typeof image === 'string') {
			image = image === '' ? './assets/placeholder/asset.png' : image;
			return image;
		}

		const resource = image as Resource;
		return Service.getURL(ServiceList.resources.image.get, {
			uuid: resource.uuid,
			format: resource.format
		});
	}

	/**
	 * Closes the modal and resets the page.
	 */
	public closeModal(reload: boolean = false): void {
		App.popover.dismiss();
	}

	/**
	 * Opens the modal that has all the calendar occurrences for that asset.
	 */
	public async openCalendarModal(): Promise<void> {
		await Modal.component(AssetCalendarListComponent, {asset: this.asset}, false, false);
	}
}
