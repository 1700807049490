import {Component, OnInit, ViewChild} from '@angular/core';
import {UnoSearchBarResult} from 'src/app/components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';
import {ButtonSize, ButtonColor} from 'src/app/components/uno/uno-button/uno-button.component';
import {UnoTableColumnLayout, UnoTableColumnType, UnoTableComponent} from 'src/app/components/uno/uno-table/uno-table.component';
import {AsyncUtils} from 'src/app/utils/async-utils';
import {UpperCasePipe} from '@angular/common';
import {ListDisplayStyle, UnoResponsiveTableListComponent} from 'src/app/components/uno/uno-responsive-table-list/uno-responsive-table-list.component';
import {RandomUtils} from 'src/app/utils/random-utils';
import {UnoUuidComponent} from 'src/app/components/uno-input/uno-uuid/uno-uuid.component';
import {Repair} from 'src/app/models/repairs/repairs/repair';
import {RepairService} from 'src/app/modules/repairs/repair-work/services/repair.service';
import {UnoTreeLayout, UnoTreeLayoutType} from 'src/app/components/uno/uno-tree/uno-tree-layout';
import {UUID, generateUUID} from '../../../../models/uuid';
import {App} from '../../../../app';
import {ScreenComponent} from '../../../../components/screen/screen.component';
import {UnoFilterBarOption, UnoFilterBarOptionType, UnoFilterBarComponent} from '../../../../components/uno/uno-filter-bar/uno-filter-bar.component';
import {SortDirection} from '../../../../utils/sort-direction';
import {
	InputOptionsMultipleBatchRequest,
	InputOptionsMultipleLazyPageRequest
} from '../../../../components/uno-input/uno-options-lazy/uno-options-lazy.component';
import {Service} from '../../../../http/service';
import {ServiceList} from '../../../../http/service-list';
import {Session} from '../../../../session';
import {UnoBadgeComponent} from '../../../../components/uno/uno-badge/uno-badge.component';
import {UnoLateralSelectorSwitchComponent} from '../../../../components/uno/uno-lateral-selector-switch/uno-lateral-selector-switch.component';
import {UnoPeriodicitySelector} from '../../../../components/uno/uno-periodicity-selector/uno-periodicity-selector.component';
import {UnoSearchbarDropdownComponent} from '../../../../components/uno/uno-searchbar-dropdown/uno-searchbar-dropdown.component';
import {UnoOptionsSearchComponent} from '../../../../components/uno/uno-options-search/uno-options-search.component';
import {UnoOptionsList} from '../../../../components/uno/uno-options/uno-options-list/uno-options-list.component';
import {UnoTooltipDirective} from '../../../../components/uno/uno-tooltip/uno-tooltip.directive';
import {UnoTextComponent} from '../../../../components/uno/uno-text/uno-text.component';
import {UnoSwitchComponent} from '../../../../components/uno/uno-switch/uno-switch.component';
import {UnoInputComponent} from '../../../../components/uno/uno-input/uno-input-component';
import {UnoNoDataComponent} from '../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoButtonComponent} from '../../../../components/uno/uno-button/uno-button.component';
import {UnoTabSectionComponent} from '../../../../components/uno/uno-tab/uno-tab-section/uno-tab-section.component';
import {UnoTabComponent} from '../../../../components/uno/uno-tab/uno-tab.component';
import {UnoTreeComponent, UnoTreeLoadData} from '../../../../components/uno/uno-tree/uno-tree.component';

@Component({
	selector: 'demo-components-page',
	templateUrl: 'demo-components.page.html',
	standalone: true,
	imports: [
		UnoTabComponent,
		UnoTabSectionComponent,
		UnoButtonComponent,
		UnoNoDataComponent,
		UnoFilterBarComponent,
		UnoInputComponent,
		UnoSwitchComponent,
		UnoUuidComponent,
		UnoTextComponent,
		UnoTooltipDirective,
		UnoOptionsList,
		UnoOptionsSearchComponent,
		UnoSearchbarDropdownComponent,
		UnoPeriodicitySelector,
		UnoLateralSelectorSwitchComponent,
		UnoBadgeComponent,
		UnoTableComponent,
		UpperCasePipe,
		UnoResponsiveTableListComponent,
		UnoTreeComponent
	]
})
export class DemoComponentsPage extends ScreenComponent implements OnInit {
	public selfStatic: any = DemoComponentsPage;

	public app: any = App;

	@ViewChild(UnoTableComponent) 
	public table: UnoTableComponent;

	@ViewChild(UnoResponsiveTableListComponent) 
	public responsiveTableList: UnoResponsiveTableListComponent;

	public text: string = this.randomText(100);

	public options: any[] = [];

	public smallOptions: any[] = [];

	public test: any = {
		test: 1,
		banana: 'amarelo', 
		batata: true,
		macaco: [1, 2, 3, 4],
		chimpanze: {
			test: 1,
			banana: 'amarelo', 
			batata: true,
			chimpanze: {
				test: 1,
				banana: 'amarelo', 
				batata: true
			}
		}
	};

	/**
	 * The maximum number of items to show on table component.
	 */
	public static tableTotalItemsCount: number = 1e5;
	
	/**
	 * The number of items to show on table per page.
	 */
	public static tablePageSize: number = 5;

	/**
	 * The items already fetched from dummy API.
	 */
	public tableItems: any[] = [];

	/**
	 * The rows that are checked in the table
	 */
	public checkedTableRows: number[] = [];

	/**
	 * The layout to use on the Uno Table component.
	 */
	public tableLayout: UnoTableColumnLayout[] = [
		{header: 'image', type: UnoTableColumnType.IMAGE, attribute: 'image', visible: true, size: 'small', tag: ListDisplayStyle.LABEL},
		{header: 'status', type: UnoTableColumnType.STATUS, attribute: 'status', visible: true, size: 'small', tag: ListDisplayStyle.LABEL},
		{header: 'title', type: UnoTableColumnType.TEXT, attribute: 'title', visible: true, size: 'medium', tag: ListDisplayStyle.TITLE, sortBy: 'title'},
		{header: 'uuid', type: UnoTableColumnType.TEXT, attribute: 'uuid', visible: false, size: 'medium', tag: ListDisplayStyle.TEXT, sortBy: 'uuid'},
		{header: 'description', type: UnoTableColumnType.TEXT, attribute: 'description', visible: true, size: 'large', tag: ListDisplayStyle.TEXT, sortBy: 'description'},
		{header: 'inspectionFields', type: UnoTableColumnType.TEXT, attribute: 'inspectionFields', visible: false, size: 'medium', tag: ListDisplayStyle.TEXT},
		{header: 'recommendations', type: UnoTableColumnType.NUMBER, attribute: 'recommendations', visible: true, size: 'small', tag: ListDisplayStyle.LABEL, sortBy: 'recommendatioins'},
		{header: 'actionPlan', type: UnoTableColumnType.NUMBER, attribute: 'actionPlan', visible: true, size: 'small', tag: ListDisplayStyle.LABEL, sortBy: 'actionPlan'},
		{header: 'Translated', type: UnoTableColumnType.TEXT, attribute: 'asset', visible: true, size: 'large', tag: ListDisplayStyle.TEXT},
		{header: 'Not translated', type: UnoTableColumnType.TEXT, attribute: 'assetUntranslated', visible: true, size: 'large', tag: ListDisplayStyle.TEXT, skipTranslation: true},
		{header: 'actions', type: UnoTableColumnType.ICONS, attribute: 'actions', visible: true, size: 'small', tag: ListDisplayStyle.LABEL, iconNumber: 5}
	];

	/**
	 * Method to load mocked tree items.
	 */
	public treeLoadItems: UnoTreeLoadData = async function(item: any): Promise<any[]> {
		await AsyncUtils.await(RandomUtils.randomNumber(200, 500));
		const count = RandomUtils.randomNumber(10, 15);
		const items = [];
		for (let i = 0; i < count; i++) {
			items.push({
				a: RandomUtils.randomText(2),
				b: RandomUtils.randomText(3),
				c: RandomUtils.randomText(20),
				d: '/assets/placeholder/asset.png',
				hasChildren: RandomUtils.randomBool()
			});
		}
		

		return items;
	};

	/**
	 * Method to call when a tree item is clicked.
	 */
	public treeOnClick = function(item: any): void {
		console.log('EQS: Tree item clicked: ', item);
	};
	
	/**
	 * Tree item layout.
	 */
	public treeLayout: UnoTreeLayout[] = [
		{
			attribute: 'a',
			type: UnoTreeLayoutType.TITLE
		}, 
		{
			attribute: 'b',
			type: UnoTreeLayoutType.LABEL
		},
		{
			attribute: 'c',
			type: UnoTreeLayoutType.LABEL
		},
		{
			attribute: 'd',
			type: UnoTreeLayoutType.PICTURE
		}
	];

	/**
	 * The layout to use on the Uno Tree component.
	 */
	public filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.SEARCH,
			attribute: 'search',
			default: ''
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'options',
			label: 'Options',
			default: null,
			options: [
				{label: 'none', value: null},
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			multiple: true,
			attribute: 'optionsMultiple',
			label: 'Options Multiple',
			default: [],
			options: [
				{label: 'name', value: 'name'},
				{label: 'createdAt', value: 'created_at'},
				{label: 'updatedAt', value: 'updated_at'}
			]
		},
		{
			type: UnoFilterBarOptionType.CHECKBOX,
			attribute: 'checkbox',
			label: 'Checkbox',
			default: false
		},
		{
			type: UnoFilterBarOptionType.BUTTON,
			label: 'Button',
			onClick: () => {alert('Button pressed');}
		},
		{
			type: UnoFilterBarOptionType.OPTIONS_LAZY,
			attribute: 'optionsLazy',
			label: 'Options Lazy',
			default: null,
			fetchOptionsLazy: async function(request: InputOptionsMultipleLazyPageRequest): Promise<void> {
				const data = {
					status: -1,
					from: request.from,
					count: request.count,
					search: request.search
				};

				try {
					const req = await Service.fetch(ServiceList.repairs.list, null, null, data, Session.session);
					request.onFinish(req.response.repairs, req.response.hasMore, req.id);
				} catch {
					request.onError();
				}
			},
			fetchOptionsBatch: async function(request: InputOptionsMultipleBatchRequest): Promise<void> {
				if (request.options.length > 0) {
					const repair: Repair = await RepairService.get(request.options[0]);
					request.onFinish([repair]);
				}
				request.onFinish([]);
			},
			getOptionText: function(option: any): string {
				return (option.asset.name ? option.asset.name : '') + (option.description ? ' - ' + option.description : '');
			}
		}
	];

	public filters = {};

	public buttonSize: any[] = Object.values(ButtonSize);

	public buttonColor: any[] = Object.values(ButtonColor);
	
	public uuid: UUID = generateUUID();

	/**
	 * Simulates a fecth request from a dummy list.
	 *
	 * @param from - Where to start fetching elements.
	 * @param count - How many elements to fetch.
	 * @returns Simulates an API list request response with an object containing a list of elements and a boolean hasMore attribute simulating more items to fecth from API.
	 */
	public loadTableItems = function() {
		const c = DemoComponentsPage.tableTotalItemsCount;
		const tableItems = [];

		// Generate a dummy list of elements to show on the table.
		for (let i = 0; i < c; i++) {

			const actions = [];
			const actionCount = RandomUtils.randomNumber(1, 7, 0);
			const icons = ['add.svg', 'arrow-left.svg', 'arrow-right.svg', 'bin.svg', 'checklist.svg', 'comments.svg'];

			for (let j = 0; j < actionCount; j++) {
				actions.push({
					src: 'assets/icons/uno/' + icons[j % icons.length],
					click: (): void => {
						App.openInTab('/menu', {});
					}
				});
			}

			tableItems.push({
				image: './assets/placeholder/asset.png',
				title: 'Title',
				uuid: generateUUID(),
				status: i % 4 === 0 ? 'var(--gray-10)' : i % 3 === 0 ? 'var(--error-normal)' : i % 2 === 0 ? 'var(--warning-normal)' : 'var(--success-normal)',
				description: 'Description',
				asset: 'asset',
				assetUntranslated: 'asset',
				inspectionFields: 'Inspection Fields',
				recommendations: Math.floor(Math.random() * 1e8),
				actionPlan: Math.floor(Math.random() * 1e8),
				actions: actions
			});
		}
	
		return async function(from: number, count: number): Promise<{elements: any[]; hasMore: boolean;}> {
			const response: {elements: any[]; hasMore: boolean;} = {elements: [], hasMore: true};

			if (from > c) {
				response.hasMore = false;
				return response;
			}

			response.elements = tableItems.slice(from, from + count);
			response.hasMore = from + count < c;

			await AsyncUtils.await(Math.random() * 1000);
			return response;
		};
	}();

	public searchBarRetrieveOptions = async function(text: string): Promise<UnoSearchBarResult[]> {
		// Simulate API delay
		await new Promise((accept, reject) => {
			setTimeout(accept, 100);
		});

		const list: UnoSearchBarResult[] = [
			{label: 'a', value: 0},
			{label: 'asdasd', value: 1},
			{label: 'fdgdcxzcxz zxc xzc xz ccz asadasdsa dsadsa sdad sad sas adas ds sad d asd a sdag3tergdfgfd fga', value: 2},
			{label: 'atrrasdsa sa ddsad sad asd tytr', value: 3},
			{label: 'sdfxzcxz czc zx cxzvbcxbcva', value: 4},
			{label: 'acbvvzxczx czx czxc c', value: 5},
			{label: 'cvzxc xzc zxc xzbca', value: 6},
			{label: 'etrtcx zc zxxc zc ertrea', value: 7}
		];

		return list.filter(function(val) {
			return val.label.indexOf(text) > -1;
		});
	};

	public ngOnInit(): void {
		super.ngOnInit();
		
		App.navigator.setTitle('components');

		this.getOptions();
		this.getSmallOptions();
	}

	/**
	 * Generate a list of options for uno-options components.
	 *
	 * @param count - How many options to generate.
	 */
	public getOptions(count: number = 20): void {
		for (let i = 0; i < count; i++) {
			this.options.push({
				label: this.randomText(3),
				value: i
			});
		}
	}

	/**
	 * Generate a list of single word options for uno-options components.
	 *
	 * @param count - How many options to generate.
	 */
	public getSmallOptions(count: number = 20): void {
		for (let i = 0; i < count; i++) {
			this.smallOptions.push({
				label: this.randomText(1),
				value: i
			});
		}
	}

	/**
	 * Generate random text. Used for demonstration purposes in the demo components.
	 */
	public randomText(words: number = 10): string {
		let dict = '';
		for (let i = 'a'.charCodeAt(0), end = 'z'.charCodeAt(0); i < end; i++) {
			dict += String.fromCharCode(i);
		}

		let result = '';

		for (let i = 0; i < words; i++) {
			const size = Math.ceil(Math.random() * 5) + 4;
			for (let j = 0; j < size; j++) {
				result += dict[Math.floor(Math.random() * dict.length)];
			}

			result += ' ';
		}

		return result;
	}

	/**
	 * Shows when a component changes and what is their output values.
	 * 
	 * @param output - The output to show.
	 */
	public componentChanges(output: any): void {
		console.log('EQS: Component changes output: ', output);
	}

	/**
	 * Updates when a table row is checked
	 * 
	 * @param output - The checked rows
	 */
	public checkedRow(output: any): void {
		if (typeof output === 'boolean') {
			this.checkedTableRows = [];
		} else {
			this.checkedTableRows = output;
		}

		this.componentChanges(output);
	}
}
