import {UUID} from '../../../models/uuid';
import {
	InputOptionsMultipleBatchRequest,
	InputOptionsMultipleLazyPageRequest
} from '../../uno-input/uno-options-lazy/uno-options-lazy.component';
import {OptionSelectorOption} from '../../uno-input/uno-options/uno-options.component';
import {UnoFormItemSize} from './uno-form-item-size';

/**
 * Options field display mode.
 */
export const OptionsDisplayMode = {
	/**
	 * Popover mode.
	 */
	POPOVER: 1,

	/**
	 * Modal mode.
	 */
	MODAL: 2
};

/**
 * Map of the options field display mode and its corresponding label as stored in locale.
 */
export const OptionsDisplayModeLabel: Map<number, string> = new Map([
	[OptionsDisplayMode.POPOVER, 'popover'],
	[OptionsDisplayMode.MODAL, 'modal']
]);

/**
 * Structure of a form field, used to generate forms to edit objects.
 *
 * Some type of field might require additional parameters specified in their documentation.
 */
export class UnoFormField {
	/**
	 * Type of the field (TEXT, NUMBER, EMAIL, ...)
	 *
	 * Indicates the type of data of the field and how that data should be selected.
	 */
	public type: number = 0;

	/**
	 * Display a button to copy the content of the field to the clipboard.
	 *
	 * Only used for some types (e.g TEXT)
	 */
	public copy?: boolean = false;

	/**
	 * Name of the object attribute to edit.
	 *
	 * Attribute names can indicate nested objects (e.g. `a:{b:{c:2}}` the c value can be accessed as "a.b.c").
	 */
	public attribute?: string;

	/**
	 * Label shown to the user in the form. If no label is specified the attribute value is used instead.
	 *
	 * Labels are translated before being shown to the user.
	 */
	public label?: (string | ((object: any, row: UnoFormField)=> string));

	/**
	 * Size of the element.
	 */
	public size?: UnoFormItemSize;

	/**
	 * List of equipments sub-types that this Atex inspection field is applied to.
	 *
	 * Only used for ATEX_INSPECTION_FIELD type.
	 */
	public onlyApplicable?: UUID[];

	/**
	 * If the fields is not editable only its value will be displayed.
	 *
	 * Can be a boolean or a Function that return a boolean value, it receives (object, row) the object and itself as parameters.
	 */
	public editable?: (boolean | ((object: any, row: UnoFormField)=> boolean));

	/**
	 * Required should be indicated if the field if obligatory.
	 *
	 * Can be a boolean or a Function that return a boolean value, it receives (object, row) the object and itself as parameters.
	 */
	public required?: (boolean | ((object: any, row: UnoFormField)=> boolean));

	/**
	 * If present, indicates conditions to show or hide the field.
	 *
	 * Can be a boolean or a Function that return a boolean value, it receives (object, row) the object and itself as parameters.
	 */
	public isActive?: (boolean | ((object: any, row: UnoFormField)=> boolean));

	/**
	 * List of options possible for the form field selected, as `{value: ..., label...}`
	 *
	 * Only used for some types (e.g OPTIONS, CHECKLIST)
	 */
	public options?: OptionSelectorOption[];

	/**
	 * Display mode for the options component (either alert or popover).
	 */
	public optionsDisplayMode?: number = null;

	/**
	 * If set, user can clear the content of the object used on the field.
	 *
	 * Only used for some types (e.g OPTIONS, OPTIONS_MULTIPLE, OPTIONS_MULTIPLE_LAZY, USER_SELECTOR, ASSET_SELECTOR, TEAM_SELECTOR, COMPANY_SELECTOR)
	 */
	public showClear?: boolean = false;

	/**
	 * If set, options are sorted alphabetically.
	 *
	 * Only used for some types (e.g OPTIONS, OPTIONS_MULTIPLE).
	 */
	public sort?: boolean = true;

	/**
	 * If set, options cannot be sorted individually.
	 *
	 * Only used for some types (e.g REPETITIVE_FORM and KEY_VALUE_ARRAY).
	 */
	public disableSort?: (boolean | ((object: any, row: UnoFormField)=> boolean)) = false;

	/**
	 * If set, options cannot deleted individually.
	 *
	 * Only used for some types (e.g REPETITIVE_FORM and KEY_VALUE_ARRAY).
	 */
	public disableDelete?: (boolean | ((object: any, row: UnoFormField)=> boolean)) = false;

	/**
	 * Indicates if the form field should be filled by the backoffice team.
	 *
	 * Only used for (e.g ATEX_INSPECTION_FIELD)
	 */
	public backoffice?: boolean;

	/**
	 * Indicates if the form field should be filled by the inspector.
	 *
	 * Only used for (e.g ATEX_INSPECTION_FIELD)
	 */
	public inspector?: boolean;

	/**
	 * Possible justifications for a inspection field. The list should be a array of justifications.
	 *
	 * The selected justifications are stored by their index in the array.
	 *
	 * Only used for (e.g ATEX_INSPECTION_FIELD)
	 */
	public justifications?: string[];

	/**
	 * Indicates if the field allow for the selection of multiple values.
	 *
	 * Only used for some types (e.g OPTIONS)
	 */
	public multiple?: boolean;

	/**
	 * Indicates if data should be compressed before submission.
	 *
	 * If true, the image is re-encoded and resized to match the values defined in the settings.
	 *
	 * Use for images types (e.g. IMAGE_RESOURCE)
	 */
	public compress?: boolean;

	/**
	 * Asset type to be selected. Only applies to ASSET_SELECTOR field type.
	 *
	 * If set to -1 all assets are shown.
	 */
	public assetType?: number;

	/**
	 * Method to validate if the value is filled if the required flag is set true.
	 *
	 * Receives (object, row) the object and itself as parameters.
	 */
	public isEmpty?: (object: any, row: UnoFormField)=> boolean;

	/**
	 * On change method called every time the value of this row is changed.
	 */
	public onChange?: (object: any, row: UnoFormField, value: any, layout: UnoFormField[])=> void;

	/**
	 * Method called everytime an options list is opened.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public onOpen?: ()=> void = null;

	/**
	 * The callback to be called after adding a new item. It receives the created item object as param.
	 *
	 * Only used for the repetitive form field type.
	 */
	public createItem?: ()=> any = null;

	/**
	 * Method called everytime an options list is closed.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public onClose?: ()=> void = null;

	/**
	 * The method called everytime an option is selected from an open options list.
	 */
	public onSelect?: (item: any, isSelected: boolean)=> void = null;

	/**
	 * Method to get options or any setting required for the field.
	 *
	 * Received the (object, row) as parameters that can be used to store the results.
	 */
	public fetchOptions?: (object: any, row: UnoFormField)=> void;

	/**
	 * Invert the value stored in attribute.
	 *
	 * Only used for some types (e.g CHECKBOX, TOGGLE)
	 */
	public invert?: boolean;

	/**
	 * Unit value shown after the value for some types of fields.
	 *
	 * Only used for some types (e.g NUMBER, NUMBER_UNITS)
	 */
	public unit?: string;

	/**
	 * Input unit used to convert from the input to the output unit.
	 *
	 * This is the units used by the user to introduce data, the output unit is indicated by the "unit" attribute.
	 *
	 * Only used for NUMBER_UNITS input type.
	 */
	public inUnit?: string;

	/**
	 * Attribute of the objects places in the form entry to consider as key.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public identifierAttribute?: string;

	/**
	 * Lazy fetch options from the API for infinite scrollable list.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public fetchOptionsLazy?: (request: InputOptionsMultipleLazyPageRequest, object?: any)=> void;

	/**
	 * Fetch batch options from the API to fill already existing values.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public fetchOptionsBatch?: (request: InputOptionsMultipleBatchRequest, object?: any)=> void;

	/**
	 * Get the list options text.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE_LAZY)
	 */
	public getOptionText?: (obj: any)=> string;

	/**
	 * Minimum value used for types that need range control.
	 *
	 * Only used for some types (e.g NUMBER_RANGE, NUMBER_SLIDER)
	 */
	public min?: number;

	/**
	 * Maximum value used for types that need range control.
	 *
	 * Only used for some types (e.g NUMBER_RANGE, NUMBER_SLIDER)
	 */
	public max?: number;

	/**
	 * Start date of the selector.
	 *
	 * Only used for some types (e.g DATE)
	 */
	public startDate?: Date;

	/**
	 * End date of the selector.
	 *
	 * Only used for some types (e.g DATE)
	 */
	public endDate?: Date;

	/**
	 * Value step size.
	 *
	 * Only used for some types (e.g NUMBER_RANGE, NUMBER_SLIDER)
	 */
	public step?: number;

	/**
	 * List of fields to be placed inside of this field.
	 *
	 * Use for form container types (e.g. SUB_FORM)
	 */
	public fields?: UnoFormField[];

	/**
	 * Indicates if the collapsable sub form is expanded or hidden.
	 *
	 * Used for form container types (e.g. SUB_FORM)
	 */
	public expanded?: boolean;

	/**
	 * Indicates the file types that can be selected.
	 *
	 * Only used for some types (e.g DOCUMENT_RESOURCE_MULTIPLE, DOCUMENT_RESOURCE)
	 */
	public filter?: string;

	/**
	 * Indicates if input notes field should be shown on INSPECTION_FIELD form field.
	 *
	 * Only used on form field of type INSPECTION_FIELD
	 */
	public showNotes?: boolean;

	/**
	 * Indicates if input documents field should be shown on INSPECTION_FIELD form field.
	 *
	 * Only used on form field of type INSPECTION_FIELD
	 */
	public showDocuments?: boolean;

	/**
	 * Indicates if pictures input field should be shown on INSPECTION_FIELD form field.
	 *
	 * Only used on form field of type INSPECTION_FIELD
	 */
	public showPictures?: boolean;

	/**
	 * Sample data to be placed in the field.
	 *
	 * Only used on form field DOCUMENT_RESOURCE.
	 */
	public sampleData?: string;

	/**
	 * Indicates the smallest precision for the DateFrequency component.
	 *
	 * Only used on form fields of type DATE_FREQUENCY.
	 */
	public dateFrequencyPrecision?: string;

	/*
	 * Translate the options shown to the user.
	 *
	 * Only used for some types (e.g OPTIONS_MULTIPLE, OPTIONS_MULTIPLE_LAZY, OPTIONS)
	 */
	public translate?: boolean;

	/**
	 * Indicates if the file selected should only be stored locally.
	 *
	 * Only used for some type (e.g. IMAGE_RESOURCE, IMAGE_RESOUCE_MULTIPLE, DOCUMENT_RESOURCE and DOCUMENT_RESOURCE_MULTIPLE)
	 */
	public local?: boolean;

	/**
	 * Placeholder label data to be used when no option is selected.
	 *
	 * Only used for OPTIONS_MULTIPLE_LAZY.
	 */
	public placeholder?: string;

	/**
	 * If the form borders should be shown
	 *
	 * Only used for REPETITIVE_FORM,
	 */
	public border?: boolean = true;
}
