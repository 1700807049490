import {Component, OnInit} from '@angular/core';
import {SortDirection} from 'src/app/utils/sort-direction';
import {TranslateModule} from '@ngx-translate/core';
import {CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf} from '@angular/cdk/scrolling';
import {NgStyle} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {UnoFilterBarComponent, UnoFilterBarOption, UnoFilterBarOptionType} from 'src/app/components/uno/uno-filter-bar/uno-filter-bar.component';
import {App} from '../../../../../app';
import {Service} from '../../../../../http/service';
import {Session} from '../../../../../session';
import {ServiceList} from '../../../../../http/service-list';
import {Locale} from '../../../../../locale/locale';
import {ScreenComponent} from '../../../../../components/screen/screen.component';
import {UserPermissions} from '../../../../../models/users/user-permissions';
import {UnoFormField} from '../../../../../components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from '../../../../../components/uno-forms/uno-form/uno-form-field-types';
import {Modal} from '../../../../../modal';
import {ServiceSync} from '../../../../../http/service-sync';
import {APAsset} from '../../../../../models/asset-portfolio/asset';
import {Atex} from '../../../../../models/atex/atex';
import {UnoListLazyLoadHandler} from '../../../../../components/uno/uno-list/uno-list-lazy-load-handler';
import {UnoNoDataComponent} from '../../../../../components/uno/uno-no-data/uno-no-data.component';
import {UnoContentComponent} from '../../../../../components/uno/uno-content/uno-content.component';
import {UnoSearchbarComponent} from '../../../../../components/uno/uno-searchbar/uno-searchbar.component';
import {ResourceUtils} from '../../../../../utils/resource-utils';

const ModelLayout: UnoFormField[] = [
	{
		label: 'model',
		attribute: 'model',
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'manufacturer',
		attribute: 'manufacturer',
		type: UnoFormFieldTypes.TEXT
	},
	{
		label: 'atex',
		attribute: 'atex',
		type: UnoFormFieldTypes.ATEX_FIELD,
		isActive: function(object) { return object.atex !== null; }
	},
	{
		label: 'documents',
		attribute: 'documents',
		type: UnoFormFieldTypes.DOCUMENT_RESOURCE_MULTIPLE
	}
];

/**
 * Represent a model entry in the list.
 */
class ModelEntry {
	/**
	 * Name of the model. Models can have the same name for different manufacturers.
	 */
	public model: string = null;

	/**
	 * Name of the manufacturer.
	 */
	public manufacturer: string = null;

	/**
	 * List of asset associated with this model.
	 */
	public assets: APAsset[] = null;

	/**
	 * Indicates if the list entry is expanded (assets visible)
	 */
	public expanded: boolean = false;

	public constructor(data) {
		this.model = data.model;
		this.manufacturer = data.manufacturer;
	}

	/**
	 * Get the assets that are based on this model/manufacturer.
	 *
	 * @returns List of all the assets for this model and manufacturer.
	 */
	public async getAssets(): Promise<APAsset[]> {
		if (!this.assets) {
			const data = {
				model: this.model,
				manufacturer: this.manufacturer
			};

			const request = await Service.fetch(ServiceList.assetPortfolio.asset.listByModel, null, null, data, Session.session);

			const assets = [];
			for (let i = 0; i < request.response.assets.length; i++) {
				assets.push(APAsset.parse(request.response.assets[i]));
			}

			this.assets = assets;
		}

		return Promise.resolve(this.assets);
	}
}

/**
 * Screen to view a list of all manufacturer/model available and the list equipments of each combo.
 *
 * Allows to more easily perceive if data filled for each equipment is consistent with data from other equipments.
 */
@Component({
	selector: 'assets-model-list-page',
	templateUrl: 'asset-model-list.page.html',
	standalone: true,
	imports: [
		UnoSearchbarComponent,
		IonicModule,
		FormsModule,
		UnoContentComponent,
		NgStyle,
		UnoNoDataComponent,
		CdkVirtualScrollViewport,
		CdkFixedSizeVirtualScroll,
		CdkVirtualForOf,
		TranslateModule,
		UnoFilterBarComponent
	]
})
export class AssetsModelListPage extends ScreenComponent implements OnInit {
	public settings = Session.settings;

	public app: any = App;

	public selfStatic: any = AssetsModelListPage;

	public permissions = [UserPermissions.ASSET_PORTFOLIO_MODEL_LIST];

	/**
	 * List of models available received from the API to display on the screen.
	 */
	public models: ModelEntry[] = [];

	/**
	 * Lazy list loading handler.
	 */
	public handler: UnoListLazyLoadHandler<ModelEntry> = new UnoListLazyLoadHandler<any>();

	/**
	 * Object to synchronize service requests.
	 */
	public serviceSync: ServiceSync = new ServiceSync();

	public constructor() {
		super();

		this.handler.loadMore = async(count: number, pageSize: number) => {
			const data = {
				from: count,
				count: pageSize,
				search: AssetsModelListPage.filters.search,
				searchFields: AssetsModelListPage.filters.searchFields,
				sortDirection: AssetsModelListPage.filters.sortDirection,
				sortField: AssetsModelListPage.filters.sortField
			};

			const request = await this.serviceSync.fetch(ServiceList.assetPortfolio.asset.listModels, null, null, data, Session.session);
			const response = request.response;

			const models = [];
			for (let i = 0; i < response.models.length; i++) {
				models.push(new ModelEntry(response.models[i]));
			}

			return {
				elements: models,
				hasMore: response.hasMore
			};
		};
	}

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		App.navigator.setTitle(Locale.get('modelList'));

		this.models = [];
		await this.handler.reset();
	}

	/**
	 * Possible database filter to be used for ordering the Asset Model list.
	 */
	public static filterOptions: UnoFilterBarOption[] = [
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortDirection',
			label: 'direction',
			default: SortDirection.ASC,
			options: [
				{label: 'asc', value: SortDirection.ASC},
				{label: 'desc', value: SortDirection.DESC}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'sortField',
			label: 'sortField',
			default: 'manufacturer',
			options: [
				{label: 'manufacturer', value: 'manufacturer'},
				{label: 'model', value: 'model'}
			]
		},
		{
			type: UnoFilterBarOptionType.OPTIONS,
			attribute: 'searchFields',
			label: 'searchFields',
			default: ['name', 'tag', 'description', 'manufacturer', 'model', 'id'],
			multiple: true,
			options: [
				{label: 'manufacturer', value: 'manufacturer'},
				{label: 'model', value: 'model'},
				{label: 'name', value: 'name'},
				{label: 'tag', value: 'tag'},
				{label: 'description', value: 'description'},
				{label: 'assetUuid', value: 'id'}
			]
		}
	];

	public static filters = UnoFilterBarComponent.reset({
		/**
		 * Sort direction applied to the loaded list from database.
		 */
		sortDirection: '',

		/**
		 * Database attribute name used to sort the values.
		 */
		sortField: '',

		/**
		 * Text used to filter assets by their name, tag name or serial number.
		 */
		search: '',

		/**
		 * Search fields to be considered.
		 */
		searchFields: []
	}, AssetsModelListPage.filterOptions);

	public static defaultFilters = structuredClone(AssetsModelListPage.filters);

	public resetFilters(): void {
		this.serviceSync.reset();

		Object.assign(AssetsModelListPage.filters, AssetsModelListPage.defaultFilters);
	}

	/**
	 * Update filters and reload data from the API.
	 *
	 * @param event - DOM event.
	 */
	public onFilterChange(filters: any): void {
		this.serviceSync.reset();
		this.handler.reset();
	}

	/**
	 * Update the search term used.
	 *
	 * @param event - DOM event.
	 */
	public onSearch(event: any): void {
		AssetsModelListPage.filters.search = event;

		this.serviceSync.reset();
		this.handler.reset();
	}

	/**
	 * Edit model and manufacturer name in bulk for all equipments that have a value.
	 *
	 * Can also upload documents to all equipments of this model.
	 *
	 * @param model - Model object entry to be edited.
	 * @param asset - Asset to get the Atex specification from.
	 * @param event - DOM event to be cancelled to prevent triggering other actions.
	 */
	public async editModel(model: ModelEntry, asset?: APAsset, event?: Event): Promise<void> {
		if (event !== undefined) {
			event.preventDefault();
			event.stopPropagation();
		}

		// Auxiliary method to update the asset with new data.
		async function updateAsset(a: APAsset, data: any): Promise<void> {
			const assetCopy = structuredClone(a);
			assetCopy.model = data.model;
			assetCopy.manufacturer = data.manufacturer;
			assetCopy.documents = assetCopy.documents.concat(data.documents);

			if (data.atex) {
				if (!assetCopy.atex) {
					assetCopy.atex = new Atex();
				}
				assetCopy.atex.tags = data.atex.tags;
			}

			await Service.fetch(ServiceList.assetPortfolio.asset.update, null, null, assetCopy, Session.session);
		}

		const options = {
			model: model.model,
			manufacturer: model.manufacturer,
			atex: asset && asset.atex ? structuredClone(asset.atex) : new Atex(),
			documents: []
		};

		let buttons = [];

		if (asset !== undefined) {
			buttons.push({
				success: true,
				label: 'update',
				color: 'primary',
				callback: async(data) => {
					await updateAsset(asset, data);
					Modal.toast(Locale.get('updatedSuccessfully'));
					this.handler.reset();
				}
			});
		}

		buttons = buttons.concat([
			{
				success: true,
				label: 'updateAll',
				color: 'primary',
				callback: async(data) => {
					const assets = await model.getAssets();
					const promises = [];
					for (let i = 0; i < assets.length; i++) {
						promises.push(updateAsset(assets[i], data));
					}
					await Promise.all(promises);

					Modal.toast(Locale.get('updatedSuccessfully'));
					this.handler.reset();
				}
			},
			{
				success: false,
				label: 'cancel',
				color: 'primary',
				callback: null
			}
		]);

		await Modal.form(Locale.get('model'), options, ModelLayout, buttons);
	}

	/**
	 * Get equipment from model and manufacturer list and expand the model view.
	 *
	 * @param model - Model representation composed of model and manufacturer (received from the API).
	 */
	public expand(model: ModelEntry): void {
		// Get model assets if necessary
		if (!model.assets) {
			model.getAssets();
		}

		// Toggle expanded flag
		model.expanded = !model.expanded;
	}

	protected readonly ResourceUtils = ResourceUtils;
}
