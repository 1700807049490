import {UnoFormField} from 'src/app/components/uno-forms/uno-form/uno-form-field';
import {UnoFormFieldTypes} from 'src/app/components/uno-forms/uno-form/uno-form-field-types';
import {RoleUsageLabel} from '../../data/roles-usages';

/**
 * Layout of the form used to show where a role is used.
 */
export const RoleUsagesFormLayout: UnoFormField[] = [
	{
		label: (object) => {return RoleUsageLabel.get(object.usage[0].table);},
		type: UnoFormFieldTypes.SUB_FORM,
		fields: [
			{
				label: ' ',
				type: UnoFormFieldTypes.REPETITIVE_FORM,
				attribute: 'usage',
				border: false,
				fields: [
					{
						attribute: 'uuid',
						label: (object) => {return object;},
						type: UnoFormFieldTypes.TITLE
					},
					{
						attribute: 'uuid',
						label: 'uuid',
						type: UnoFormFieldTypes.UUID
					},
					{
						attribute: 'name',
						label: 'name',
						type: UnoFormFieldTypes.TEXT,
						isActive: (object) => {return !['atex_inspection', 'repair', 'repair_inspection', 'ga_gap', 'calendar_event_occurrence'].includes(object.table);}
					},
					{
						attribute: 'tag',
						label: 'tag',
						type: UnoFormFieldTypes.TEXT,
						isActive: (object) => {return object.table === 'ap_asset';}
					},
					{
						attribute: 'description',
						label: 'description',
						type: UnoFormFieldTypes.TEXT,
						isActive: (object) => {return !['atex_inspection', 'inspection_workflow_step', 'calendar_event_occurrence', 'repair_inspection'].includes(object.table);}
					}
				]
			}
		]
	}
];
